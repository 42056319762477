
/*
 * RISE 
 * Adjust Utilities 
 * 
 * Creates an Adjust tracking url.
 * Requires a base tracking URL, and then optionally adds creative, adgroup, and campaign.
 * Campaign always normalizes hyphens to spaces. 
 */
import { AdjustTrackingUrls } from "./globals";

 

export class AdjustTrackingUrl {

    baseUrl: string; 
    creative: string | null;
    adgroup: string | null;
    campaign: string | null;

    constructor(
        baseUrl: string | null = null, 
        creative: string | null = null,
        adgroup: string | null = null,
        campaign: string | null = null
        ) {
            
        this.baseUrl = baseUrl || AdjustTrackingUrls.PLACEHOLDER;
        this.creative = creative;
        this.adgroup = adgroup;
        this.campaign = campaign;

    }

    // Reset all values
    cleanInit() {

        this.baseUrl = AdjustTrackingUrls.PLACEHOLDER;
        this.creative = null;
        this.adgroup = null;
        this.campaign = null;

    }

    loadFromElement(element: HTMLElement, cleanInit: boolean = false) {

        if(!element) {
            console.error ("Cannot load Adjust tracking data from element, no element specified.");
            return;
        }

        if(cleanInit)
            this.cleanInit(); 

        // Extract attributes from the element and assign them to the object's properties
        this.baseUrl = element.getAttribute('adjust-url') || this.baseUrl;
        this.creative = element.getAttribute('adjust-creative') || this.creative;
        this.adgroup = element.getAttribute('adjust-adgroup') || this.adgroup;
        this.campaign = element.getAttribute('adjust-campaign') || this.campaign;

    }
    
    // Compose url
    toString(): string {

        let url = new URL(this.baseUrl);
        let params = new URLSearchParams(url.search);
        
        // Process Adjust creative param
        if (this.creative) // web-blog
            params.append('creative', this.creative);
        
        // Process Adjust adgroup param
        if (this.adgroup) // variant code
            params.append('adgroup', this.adgroup);
        
        // Process Adjust campaign param
        if (this.campaign) // post-slug w/o spaces 
            // replace hypens with spaces 
            params.append('campaign', this.campaign.replace(/-/g, " "));
    
        url.search = params.toString();
            
        return url.toString();
    }
    
}
    
    
    
    
    