
/*
 * RISE 
 * Banner Utilities 
 * 
 * Constructs Adjust tracking Urls
 * for each banner. 
 */ 

import { AdjustTrackingUrl } from './adjust';


export class Banner {

    constructor() {
    }

    safeInit() {

        try {
            this.init();
        } catch (error) {
            console.error("An unexpected error occurred in Banner:", error);
        }
        
    }

    init() {
    
        // Find all tagged banners
        document.querySelectorAll("[rise-action=install-app]").forEach((element) => {

            // Construct the Adjust tracking URL from banner custom attributes
            const anchor = element as HTMLAnchorElement;
            let url = new URL(anchor.href);
            let params = new URLSearchParams(url.search);
            
            // Process Adjust creative param
            if (anchor.getAttribute("adjust-creative")) // web-blog
                params.append('creative', anchor.getAttribute("adjust-creative") as string);
            
            // Process Adjust adgroup param
            if (anchor.getAttribute("adjust-adgroup")) // variant code
                params.append('adgroup', anchor.getAttribute("adjust-adgroup") as string);
            
            // Process Adjust campaign param
            if (anchor.getAttribute("adjust-campaign")) // post-slug w/o spaces 
                // replace hypens with spaces 
                params.append('campaign', (anchor.getAttribute("adjust-campaign") as string).replace(/-/g, " "));
            
            // Apply params
            url.search = params.toString();
            
            // Apply new url
            anchor.href = url.toString();

        });
       
    }

    // Determine the mobile OS
    // Currently this is unused as Adjust automatically performs this determination
    // and routes to the correct app store accordingly
    // getMobileOperatingSystem() {
    //     var userAgent = navigator.userAgent || navigator.vendor || window['opera'];
        
    //     // Windows Phone must come first because its UA also contains "Android"
    //     if (/windows phone/i.test(userAgent)) {
    //         return "Windows Phone";
    //     }
        
    //     if (/android/i.test(userAgent)) {
    //         return "Android";
    //     }
        
    //     // iOS detection from: http://stackoverflow.com/a/9039885/177710
    //     if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
    //         return "iOS";
    //     }
        
    //     return "unknown";
    // }

}

    
    

    
    
    
    