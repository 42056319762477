
/** 
 * RISE 
 * Blog Utilities 
 * 
 * This lib; 
 * - Adds the blog-specific Adjust tracking link to the phrase "RISE app"
 * - Replaces any existing links on that text with new Adjust tracking link
 * - Does the same for images, with the campaign set to "image"
 */
import { GTMEvent } from "./gtm-event";

 

/** 
 * USAGE
 * On any rich text element, add these custom attrs
 * - [rise-linkify-adjust] = the base tracking Url in adjust e.g. https://app.adjust.com/oha9msp
 * Optionally, on the same element, add
 * - [adjust-creative]
 * - [adjust-campaign]
 * - [adjust-adgroup]
 * Use CMS-bound attributes where appropriate
 * 
 * Inside the rich text element, images may also have these 3 adjust- attrs
 * These will override the default link construction. 
 */

// #linkify

// import { AdjustTrackingUrl } from './adjust';

// Global Rise object
// window['Rise'] = window['Rise'] || {}; 
// var Rise = window['Rise']; 

export class TocFix {

    constructor() {
    }
        
    init() {

        this.fixupAll();
        
    }
    
    // Linkify all rich text elements
    fixupAll() { 

        // Create and resolve Adjust tracking url
        // var url: AdjustTrackingUrl = new AdjustTrackingUrl(); 
        // if (Rise.adjustTrackingUrl)
        //     url = Rise.adjustTrackingUrl(url);
        // else {
        //     console.error ("No Adjust tracking handler");
        //     return; // do not linkify 
        // }

        // Get all elements with the selector
        var taggedElements = document.querySelectorAll(`[fs-toc-element=contents]`);

        // Exit, if nothing found
        if(!taggedElements) {
            console.warn ("No elements found to fixup.");
            return;
        }

        // Iterate through each element
        taggedElements.forEach((element: Element) => {

            if(!element)
                return;

            if (element.classList.contains("w-richtext")) {

                // Linkify the identified rich text element
                this.fixupRichText(element); 

            }
        
        });
    
    }
        
    // Linkify rich text content in one element
    fixupRichText(rtfElement: Element): void {

        // Assuming rtfElement is already defined and is a reference to the element you want to modify
        // e.g., const rtfElement = document.querySelector('.rtfElement');

        // 1. Unwrap divs with class .w-embed
        const embedDivs = rtfElement.querySelectorAll('.w-embed');
        embedDivs.forEach(div => {
            // Move each child node of the div to the position of the div
            while (div.firstChild) {
                div.parentNode?.insertBefore(div.firstChild, div);
            }
            // Remove the div
            div.remove();
        });

        // 2. Delete links with href beginning with #
        const hashLinks = rtfElement.querySelectorAll('a[href^="#"]');
        hashLinks.forEach(link => {
            link.remove();
        });
                
    }

    /**
     * Adds a click handler to each ToC item
     * to close the ToC sidebar on clic
     */

    // This runs Post-FS-ToC 
    addToCNavClickHandlers() { 

        const links = document.querySelectorAll('a[fs-toc-element=link]');
//        console.log("adding handlers", links.length) 
        links.forEach(link => {
            link.addEventListener('click', (event) => {

                // GTM 
                // Record click event 
                const gtm: GTMEvent = new GTMEvent();
                gtm.name = 'toc-click'; 
                gtm.data = {
                    event: "custom_interaction",
                    event_category: "User Interaction",
                    event_label: "Navigation", 
                    component_name: "toc",
                    interaction_type: "click",
                    position: "sticky-right",
                    variant: undefined
                };
//                gtm.breakpointSpec = 
                gtm.invokeEvent(); 

                // Close ToC sidebar
                const closeActionElement: HTMLLinkElement | null = document.querySelector('.toc_popout-tab.is-close-action');
                if (closeActionElement) {
                    closeActionElement.click();
                }
        
                // Optional: If you want to prevent the default behavior of the link
                // event.preventDefault();
            });
        }); 

    }

}
    
    
    

    
    
    
    