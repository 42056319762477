
/*
 * RISE 
 * Adjust Utilities 
 * 
 * Creates an Adjust tracking url.
 * Requires a base tracking URL, and then optionally adds creative, adgroup, and campaign.
 * Campaign always normalizes hyphens to spaces. 
 */ 

import QRCode from 'qrcode';
import { AdjustTrackingUrl } from './adjust';

export class AdjustTrackingQr extends AdjustTrackingUrl {

    size: number = 80;

    constructor(
        baseUrl: string | null = null, 
        creative: string | null = null,
        adgroup: string | null = null,
        campaign: string | null = null
        ) {
          
          super(
            baseUrl,
            creative,
            adgroup,
            campaign
          );

    }

    // Generate QR
    generate(canvas: HTMLCanvasElement) {

      QRCode.toCanvas(
        canvas, 
        this.toString(), 
        { 
          width: this.size, 
          color: { 
            dark: '#5922B4ff', // '#721CFFff',
            light: '#0000', // transparent 
          }
        },
        // function (error: Error | null) {
        //   if (error) {
        //     console.error(error);
        //   } else {
        //     console.log('success!');
        //   }
        // }
      );

    }
    
}
    
    
    
    
    