
/*
 * Site
 */

import { IModule, Page } from "@sygnal/sse";
import { Banner } from "./banner";
import { GTM } from "./gtm";
import { Modal } from "./modal";
import { DesktopCta } from "./cta-desktop";

// import gsap from 'gsap'; 
 

export class Site implements IModule {

  constructor() {
  }

  setup() {

    console.log("Site | Setup");

    Page.loadEngineCSS("site.css"); 
   
  }

  exec() {

    console.log("Site | Exec");

    // Remove Design-time elements
    document.querySelectorAll('[wfu-design]').forEach((element) => {
      element.remove();
    });
  
    // Banner setup 
    // Creates Adjust tracking URLs for each banner 
    (new Banner()).safeInit();
 
    // GTM tracking init
    (new GTM()).safeInit();

    // Modals
    // Add close-button handler to all identified elements
    // Suppress closed 'modals'.  
    (new Modal()).safeInit();  

    // Desktop CTA Modal
    // and QRs 
    (new DesktopCta()).safeInit();


    // Put your site-level custom code here
    // it will have full access to the DOM 

  }

}
