
/** 
 * RISE 
 * Blog Utilities 
 * 
 * This lib; 
 * - Adds the blog-specific Adjust tracking link to the phrase "RISE app"
 * - Replaces any existing links on that text with new Adjust tracking link
 * - Does the same for images, with the campaign set to "image"
 */ 

/** 
 * USAGE
 * On any rich text element, add these custom attrs
 * - [rise-linkify-adjust] = the base tracking Url in adjust e.g. https://app.adjust.com/oha9msp
 * Optionally, on the same element, add
 * - [adjust-creative]
 * - [adjust-campaign]
 * - [adjust-adgroup]
 * Use CMS-bound attributes where appropriate
 * 
 * Inside the rich text element, images may also have these 3 adjust- attrs
 * These will override the default link construction. 
 */

// #linkify

import { AdjustTrackingUrl } from './adjust';
import { RiseAttributes } from './globals';
import { GTMEvent } from './gtm-event';
import { hasAncestorWithAttribute } from './util';


export class Linkify {

    // Rich-text element-level custom attributes
    readonly LINKIFY_ADJUST_ATTR: string = 'rise-linkify-adjust';
    readonly LINKIFY_CLASS_ATTR: string = 'rise-linkify-class';
    readonly ADJUST_CREATIVE_ATTR: string = 'adjust-creative';
    readonly ADJUST_CAMPAIGN_ATTR: string = 'adjust-campaign';
    readonly ADJUST_ADGROUP_ATTR: string = 'adjust-adgroup';

    constructor() {
    }
        
    init() {

        this.linkifyTaggedElements();
        
    }
    
    // Linkify all rich text elements
    linkifyTaggedElements() { 

        // Create and resolve Adjust tracking url
        // var url: AdjustTrackingUrl = new AdjustTrackingUrl(); 
        // if (Rise.adjustTrackingUrl)
        //     url = Rise.adjustTrackingUrl(url);
        // else {
        //     console.error ("No Adjust tracking handler");
        //     return; // do not linkify 
        // }

        // Get all elements with the selector
        var taggedElements = document.querySelectorAll(`[${this.LINKIFY_ADJUST_ATTR}]`);

        // Exit, if nothing found
        if(!taggedElements) {
            console.warn ("No elements found to linkify.");
            return;
        }

        // Iterate through each element
        taggedElements.forEach((element: Element) => {

            if(!element)
                return;

            if (element.classList.contains("w-richtext")) {

                // Linkify the identified rich text element
                this.linkifyRichText(element); 

            } else if (element.tagName == "A") {

                this.linkifyLink(element as HTMLLinkElement);

            } else {

                this.linkifyRichText(element); 

            }
        
        });
    
    }

    linkifyLink(linkElement: HTMLLinkElement): void {

        const DEFAULT_CREATIVE: string = "link";

        // Get base tracking URL
        var url: AdjustTrackingUrl = new AdjustTrackingUrl(); 
        url.baseUrl = linkElement.getAttribute(this.LINKIFY_ADJUST_ATTR) as string;

        // Apply any default tracking configs
        url.creative = linkElement.getAttribute(this.ADJUST_CREATIVE_ATTR) as string || DEFAULT_CREATIVE; 
        url.adgroup = linkElement.getAttribute(this.ADJUST_ADGROUP_ATTR) as string; 
        url.campaign = linkElement.getAttribute(this.ADJUST_CAMPAIGN_ATTR) as string; 

        linkElement.href = url.toString();
        linkElement.target = "_blank";

        // GTM click tracker
        linkElement.onclick = (event: MouseEvent) => {
            this.gtmEvent("link");
        };  

    }
        
    /**
     * Linkify Rich Text Block 
     * @param rtbElement Rich text block element. 
     */

    // Linkify rich text content in one element
    linkifyRichText(rtbElement: Element): void {

        const DEFAULT_CREATIVE: string = "RISE";

        // Get base tracking URL
        var url: AdjustTrackingUrl = new AdjustTrackingUrl(); 
        url.baseUrl = rtbElement.getAttribute(this.LINKIFY_ADJUST_ATTR) as string;

        // Apply any default tracking configs
        url.creative = rtbElement.getAttribute(this.ADJUST_CREATIVE_ATTR) as string || DEFAULT_CREATIVE; 
        url.adgroup = rtbElement.getAttribute(this.ADJUST_ADGROUP_ATTR) as string; 
        url.campaign = rtbElement.getAttribute(this.ADJUST_CAMPAIGN_ATTR) as string; 

        // Add Adjust tracking links to the text content
        // to the words "RISE app"
        this.linkifyRichTextRISEApp(
            rtbElement, url
            );

        // Add Adjust tracking links to the text content
        // to the words "RISE", which are not already linked
        this.linkifyRichTextRISE(
            rtbElement, url
            );

        // Add Adjust tracking links to images within
        // the rtf
        url.creative = "image"; 
        this.linkifyRichTextImages(
            rtbElement, url
            ); 
                
    }

    // Linkify "RISE app" in rich text content
    // override the url where it is already linked
    linkifyRichTextRISEApp(rtbElement: Element, url: AdjustTrackingUrl): void {

//        const linkText: string = 'RISE\\s*app'; // The "\\s*" matches any amount of whitespace, including no whitespace
        const regex = /\bRISE\s*app\b/gi; // The 'i' flag makes it case-insensitive

        // Get class (optional)
        let linkClass: string | null;
        linkClass = rtbElement.getAttribute(this.LINKIFY_CLASS_ATTR);

        // Create the tracking URL
        const linkTrackingUrl: string = url.toString(); 

        const nodeIterator: NodeIterator = document.createNodeIterator(rtbElement, NodeFilter.SHOW_TEXT, null);
        const nodes: Node[] = [];
    
        // Get all text notes as a set of nodes 
        // excluding any nodes in a linkify exclusion zone 
        let node: Node | null;
        while (node = nodeIterator.nextNode()) {

            // Skip elements which are in a linkify exclusion zone
            if (hasAncestorWithAttribute(node.parentElement as HTMLElement, RiseAttributes.LINKIFY_EXCLUDE))
                continue;

            nodes.push(node);
        }
     
        // Iterate through nodes
        for (let textNode of nodes) {

            // Look for regex match
            // if found, this text node contains the matching text 
            if (regex.test(textNode.textContent as string)) {


                let parent: Node | null = textNode.parentNode;
                if (parent?.nodeName === 'A') { 

//                    console.log("in A", textNode.textContent); 

                    const link: HTMLAnchorElement = parent as HTMLAnchorElement; 

                    // If there is a parent A already, we override its Url 
                    link.setAttribute("href", linkTrackingUrl); 
                    link.setAttribute("target", "_blank"); 

                    // GTM click tracker
                    link.onclick = (event: MouseEvent) => {
                        this.gtmEvent("RISE_text", "purple-bold");
                    };

                    if(linkClass)
                        link.classList.add(linkClass);

                    continue; 
                }

                // Get parent, test if it's a link 
                // let parent: Node | null = textNode.parentNode;
                // if (parent && parent.nodeName !== 'A') {

                let frag: DocumentFragment = document.createDocumentFragment();
                let lastIndex: number = 0;
                let match: RegExpExecArray | null;
                regex.lastIndex = 0; // Reset the regex, because ".test()" changes the lastIndex

                while (match = regex.exec(textNode.textContent as string)) {

                    if (!textNode.textContent) continue; 

                    let text: string = textNode.textContent.substring(lastIndex, match.index);
                    frag.appendChild(document.createTextNode(text));

                    let link: HTMLAnchorElement = document.createElement('a');
                    link.textContent = match[0]; // Use the matched text, which may include extra spaces
                    link.href = linkTrackingUrl;
                    link.target = "_blank";

                    // GTM click tracker
                    link.onclick = (event: MouseEvent) => {
                        this.gtmEvent("RISE_text", "purple-bold");
                    };

                    if(linkClass)
                        link.classList.add(linkClass);
                    frag.appendChild(link);

                    lastIndex = regex.lastIndex;
                }

                if (!textNode.textContent) continue; 

                frag.appendChild(
                    document.createTextNode(textNode.textContent.substr(lastIndex))
                ); 
                parent?.replaceChild(frag, textNode);
    
            }

        }
    }

    // Linkify "RISE" in rich text content
    // DO NOT override the url where it is already linked
    linkifyRichTextRISE(rtbElement: Element, url: AdjustTrackingUrl): void {

//        const linkText: string = '\\bRISE\\b';
        const regex = /\bRISE\b/g; // The 'i' flag makes it case-insensitive

        // Get class (optional)
        let linkClass: string | null;
        linkClass = rtbElement.getAttribute(this.LINKIFY_CLASS_ATTR);
    
        // Create the tracking URL
        const linkTrackingUrl: string = url.toString(); 

        const nodeIterator: NodeIterator = document.createNodeIterator(rtbElement, NodeFilter.SHOW_TEXT, null);
        const nodes: Node[] = [];
    
        // Get text as a set of nodes 
        let node: Node | null;
        while (node = nodeIterator.nextNode()) {

            // Skip elements which are in a suppression zone
            if (hasAncestorWithAttribute(node.parentElement as HTMLElement, RiseAttributes.LINKIFY_EXCLUDE))
                continue;

            nodes.push(node);
        }
    
        // Iterate through nodes
        for (let textNode of nodes) {

            // Look for regex match
//            let regex: RegExp = new RegExp(linkText, 'g'); // We want case-sensitive matches only
            if (regex.test(textNode.textContent as string)) {

                let parent: Node | null = textNode.parentNode;
                if (parent?.nodeName === 'A') { 

//                    console.log("in A", textNode.textContent); 

                    const link: HTMLAnchorElement = parent as HTMLAnchorElement; 

                    // If there is a parent A already, we override its Url 
                    link.setAttribute("href", linkTrackingUrl); 
                    link.setAttribute("target", "_blank"); 

                    // GTM click tracker
                    link.onclick = (event: MouseEvent) => {
                        this.gtmEvent("RISE_text", "purple-bold");
                    };

                    if(linkClass)
                        link.classList.add(linkClass);

                    continue; 
                }

                // // Get parent, test if it's a link 
                // let parent: Node | null = textNode.parentNode;
                // if (parent && parent.nodeName !== 'A') {

                let frag: DocumentFragment = document.createDocumentFragment();
                let lastIndex: number = 0;
                let match: RegExpExecArray | null;
                regex.lastIndex = 0; // Reset the regex, because ".test()" changes the lastIndex

                while (match = regex.exec(textNode.textContent as string)) {

                    if (!textNode.textContent) continue; 

                    let text: string = textNode.textContent.substring(lastIndex, match.index);
                    frag.appendChild(document.createTextNode(text));

                    let link: HTMLAnchorElement = document.createElement('a');
                    link.textContent = match[0]; // Use the matched text, which may include extra spaces
                    link.href = linkTrackingUrl;
                    link.target = "_blank";

                    // GTM click tracker
                    link.onclick = (event: MouseEvent) => {
                        this.gtmEvent("RISE_text", "purple-bold");
                    };

//                    console.log("adding class", linkClass)
                    if(linkClass)
                        link.classList.add(linkClass);
//                        link.style.backgroundColor = "red"; // TEST: mode to highlight these                        
                    frag.appendChild(link);

                    lastIndex = regex.lastIndex;
                }

                if (!textNode.textContent) continue; 

                frag.appendChild(
                    document.createTextNode(textNode.textContent.substr(lastIndex))
                ); 
                parent?.replaceChild(frag, textNode);

                // } else if(parent) {

                //     // If there is a parent A already, we DO NOT override its Url 
                //     // (parent as HTMLAnchorElement).setAttribute("href", linkTrackingUrl); 
                //     // (parent as HTMLAnchorElement).setAttribute("target", "_blank"); 

                // }
    
            }

        }
    }

    // Invokes GTM event
    gtmEvent(componentName: string, variant: string | undefined = undefined) {

        // GTM 
        // Record click event 
        const gtm: GTMEvent = new GTMEvent();
        gtm.name = 'linkify-click'; 
        gtm.data = {
//            event_data: {
                event: "custom_interaction",
                event_category: "User Engagement",
                event_label: "App Store", 
                component_name: componentName, // "toc",
                interaction_type: "click",
                position: undefined,
                variant: variant
//            }
        };
//                gtm.breakpointSpec = 
        gtm.invokeEvent();         

    }

    // Given an element, returns the closest containing link 
    // or null if none found. 
    getContainingLink(elem: Element): HTMLLinkElement | null {

        let currentParent: ParentNode | null = elem.parentNode;

        // Walk through the ancestor tree
        while (currentParent != null) {

            // If a link is found, return that element
            if ((currentParent as Element).tagName === 'A') {
                return currentParent as HTMLLinkElement;
            }

            currentParent = currentParent.parentNode;
        }

        // No link found
        return null; 
    }

    // Linkify images inside the rich text content
    linkifyRichTextImages(rtbElement: Element, url: AdjustTrackingUrl): void {
        
//        console.log("rtf", rtfElement);

        const images = rtbElement.getElementsByTagName('img');
        
        for (let image of images) {

            // Skip elements which are in a suppression zone
            if (hasAncestorWithAttribute(image as HTMLElement, RiseAttributes.LINKIFY_EXCLUDE))
                continue;

            let link: HTMLLinkElement | null = this.getContainingLink(image);
        
            // Allow overriding of the Adjust tracking using custom attributes 
            const imageCreative = image.getAttribute('adjust-creative') ?? url.creative;
            const imageAdGroup = image.getAttribute('adjust-adgroup') ?? url.adgroup;
            const imageCampaign = image.getAttribute('adjust-campaign') ?? url.campaign;

            const imageTrackingUrl = new AdjustTrackingUrl(
                url.baseUrl, imageCreative, imageAdGroup, imageCampaign
            ).toString();    
        
            // Link the image to the Adjust tracking Url
            // create a link if needed 
            if (link) {

                // If there is a parent A already, we override its Url 
                link.href = imageTrackingUrl;
                link.target = "_blank";  

            } else {

                // Create a link and wrap the image 
                let wrapper = document.createElement('a');

                wrapper.href = imageTrackingUrl;
                wrapper.target = "_blank";

                // GTM click tracker
                wrapper.onclick = (event: MouseEvent) => {
                    this.gtmEvent("image");
                };        

                (image.parentNode as Element).insertBefore(wrapper, image);
                wrapper.appendChild(image);
            }

        }
    
    }

}
    
    
    

    
    
    
    